import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PlantOverview from "./pages/plant-overview";
import PlantDetails from "./pages/plant-details";

export default function App() {
  return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <PlantOverview />
            </Route>
            <Route path="/pflanzen/:id">
              <PlantDetails />
            </Route>
          </Switch>
        </div>
      </Router>
  );
}
